const middleware = {}

middleware['addParamsNoIndexTag'] = require('../middleware/addParamsNoIndexTag.ts')
middleware['addParamsNoIndexTag'] = middleware['addParamsNoIndexTag'].default || middleware['addParamsNoIndexTag']

middleware['dropIncorrectDates'] = require('../middleware/dropIncorrectDates.ts')
middleware['dropIncorrectDates'] = middleware['dropIncorrectDates'].default || middleware['dropIncorrectDates']

middleware['dropIncorrectQuoteProperties'] = require('../middleware/dropIncorrectQuoteProperties.ts')
middleware['dropIncorrectQuoteProperties'] = middleware['dropIncorrectQuoteProperties'].default || middleware['dropIncorrectQuoteProperties']

middleware['gtmPageDataLayer'] = require('../middleware/gtmPageDataLayer.ts')
middleware['gtmPageDataLayer'] = middleware['gtmPageDataLayer'].default || middleware['gtmPageDataLayer']

middleware['httpHeaders'] = require('../middleware/httpHeaders.ts')
middleware['httpHeaders'] = middleware['httpHeaders'].default || middleware['httpHeaders']

middleware['mapsUrlRedirect'] = require('../middleware/mapsUrlRedirect.ts')
middleware['mapsUrlRedirect'] = middleware['mapsUrlRedirect'].default || middleware['mapsUrlRedirect']

middleware['newRelicName'] = require('../middleware/newRelicName.ts')
middleware['newRelicName'] = middleware['newRelicName'].default || middleware['newRelicName']

middleware['set-page-header'] = require('../middleware/set-page-header.ts')
middleware['set-page-header'] = middleware['set-page-header'].default || middleware['set-page-header']

middleware['trailingSlash'] = require('../middleware/trailingSlash.ts')
middleware['trailingSlash'] = middleware['trailingSlash'].default || middleware['trailingSlash']

middleware['updateBoundsParams'] = require('../middleware/updateBoundsParams.ts')
middleware['updateBoundsParams'] = middleware['updateBoundsParams'].default || middleware['updateBoundsParams']

middleware['versionHeader'] = require('../middleware/versionHeader.ts')
middleware['versionHeader'] = middleware['versionHeader'].default || middleware['versionHeader']

export default middleware
